.retryIcon {
    position: absolute;
    top: 1em;
    right: 2em;
    color: rgb(100, 100, 100);
    
    &:hover {
        cursor: pointer;
        font-weight: bold;
    }
}
