.meeting_title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
}

.metadata-wrapper {
    margin-left: 1.4em;
    display: inline-block;
}

.iframe-wrapper {
    margin-left: 20px;
    margin-top: 40px;
}

.iframe-header {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
}

.attendeeslist {
    max-width:80%;
}

.info {
   opacity:0.5;
   &:hover {
       opacity:1;
   } 
}

$breakpoint-tablet: 1000px;
@media (max-width: $breakpoint-tablet) {
    .showwide {
        display:none !important;
    }
}

