.upcoming_past_links {
    font-size: 14px;
    padding: 5px;
    cursor: pointer;

    &:hover {
        border-bottom: 3px solid grey;
    }

    &.selected {
        font-weight: bold;
        border-bottom: 3px solid #6264a7;
        color: #6264a7;
    }
}

.header-text {
    font-weight: bold;
    font-size: 1.1em;
}

.header-wrapper {
    border: 0px solid black;
    //width: 80%;
    padding: 10px 20px 10px 20px;
    background-color: white;
    margin-bottom: 2px;
}

.table-wrapper {
    //width: 80%;
    padding: 20px;
    background-color: white;
}

.table-title {
    color: #3399fc;
    text-decoration: underline;
    
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.pagination {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;

    li {
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
    }
}

.pagination-page {
    a {
        display: block;
        padding-left: 5px;
        padding-right: 5px;
        color: grey;
        &:hover {
            text-decoration: none;
        }
    }

    &:hover {
        border-bottom: 3px solid grey;
    }
}

.pagination-page-next-previous {
    color: grey;
    &:hover {
        text-decoration: none;
    }
}

.pagination-active {
    font-weight: bold;
    border-bottom: 3px solid #6264a7 !important;

    a {
        color: #6264a7 !important;
        &:hover {
            text-decoration: none;
        }
    }
}

:global(.dark) {
    .upcoming_past_links.selected {
        font-weight: bold;
        border-bottom: 3px solid #a6a7dc;
        color: #a6a7dc;
    }

    .header-wrapper {
        background-color: #2d2c2c;
    }

    .table-wrapper {
        background-color: #2d2c2c;
    }

    .pagination-page-next-previous {
        color: white;
    }

    .pagination-page {
        a {
            color: white;
        }

        &:hover {
            border-bottom: 3px solid white;
        }
    }

    .pagination-active {
        border-bottom: 3px solid #a6a7dc !important;

        a {
            color: #a6a7dc !important;
        }
    }
}

:global(.contrast) {
    .upcoming_past_links.selected {
        font-weight: bold;
        border-bottom: 3px solid #fff;
        color: #fff;
    }

    .header-wrapper {
        background-color: #000;
    }

    .table-wrapper {
        background-color: #000;
    }

    .table-title {
        color: #ffff37;
    }

    .pagination-page-next-previous {
        color: #ffff37;
    }

    .pagination-page {
        a {
            color: #ffff37;
        }

        &:hover {
            border-bottom: 3px solid #ffff37;
        }
    }

    .pagination-active {
        border-bottom: 3px solid #fff !important;

        a {
            color: #fff !important;
        }
    }
}

$breakpoint-tablet: 700px;
@media (max-width: $breakpoint-tablet) {
    .showwide {
        display:none !important;
    }
}

