.topic-metadata {
    font-size: 0.87em;
    color: #c4c4c4;
}

.topic-description {
    border-top: 1px solid #414141;
}

.subtopic {
    margin-left: 1.5em;
}