.verify-header {
    font-size: 1.3em;
    font-weight: bold;
    margin-bottom: 1em;

}

.verify-header-inMeeting {
    font-size: 1.1em;
    margin-bottom: 2em;
}

.verification-text-first {
    font-weight: bold;
    margin-bottom: 0.5em;

    &.in-meeting {
        font-weight: normal;
        color: #afafaf;
        margin-bottom: 1em;
    }
}

.verification-text-second {
    margin-bottom: 0.5em;

    &.in-meeting {
        font-weight: normal;
        color: #afafaf;
        margin-bottom: 1em;
    }
}

.verify-input {
    width: 350px !important;
    margin-right: 15px;

    &.in-meeting {
        width: 250px !important;
        margin-bottom: 1em;
    }
}
