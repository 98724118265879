a, span.link {
    color: #6264A7;
    text-decoration: none;
    cursor: pointer;
}

.dark a, .dark span.link {
    color: #A6A7DC;
}

.contrast a, .contrast span.link {
    color: #ffff01;
}

a:hover, span.link:hover {
    text-decoration: underline;
}