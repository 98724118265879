.error {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.header {
    font-weight: bold;
    font-size: 1.2em;
}