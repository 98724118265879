.wrapper {
    background-color: #f5f5f5;
    padding: 20px;
}

:global(.dark) {
    .wrapper {
        background-color: #1f1f1f;
    }
}

:global(.contrast) {
    .wrapper {
        background-color: #000;
    }
}

.debugwindow {
    color:red;
    display:none;
}